import React from 'react';

const Statistics = () => {
    return (
        <div>
            <h2>statistics</h2>
        </div>
    );
};

export default Statistics;